
import { login, sendCaptcha, loginUser } from "@/api/index.js";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { isWeiXin } from "@/utils/bank";
import Toastrs from "@/utils/Toastrs";
import weixinLogin from "@/utils/weixinLogin";
import sondCode from '@/views/components/sondCode'

@Component
export default class MoWeixin extends Vue {
  public form: any = {
    mobile: "",
    verification: "",
    purpose: "login",
    third_temporary: "",
  };

  public dunwNum: number = 60;

  public mounted() {
    this.wxlogin();
  }

  public wxlogin() {
    if (isWeiXin()) {
      const code = weixinLogin();
      if (code) {
        let obj = this.getUrlParams((this as any).login_url);
        delete obj.appid;
        delete obj.redirect_uri;
        delete obj.user_type;
        delete obj.channels;
        
        loginUser({
          source_appid: (this as any).appid,
          third_platform: "wechat",
          channels: "third",
          third_code: code,
          ...obj,
        }).then((res: any) => {
          console.log(res);
          if (res.code == 200 || res.code ==0) {
            (this as any).loginSuccess(res.data.token, {
              openid: res.data.third_info.open_id,
            });
          } else if (res.code == 1031003 || res.code == 1031004) {
            this.form.third_temporary = res.data.third_temporary;
            Toastrs.error(`微信授权 成功 请绑定手机号`);
          } else if (res.code == 1031002) {
            Toastrs.error(`微信授权 失败 请使用手机号登录`);
          } else {
            Toastrs.error(`微信授权 失败 请使用手机号登录`);
          }
        });
      }
    } else {
      Toastrs.error(`当前非微信环境 不能进行微信授权`);
    }
  }

  public async sondCode() {
    sondCode({
      appid: (this as any).appid,
      type: "sms",
      mobile: this.form.mobile,
      number: this.dunwNum,
      setNum: (number: any) => {
        this.dunwNum = number;
      },
      setCode: (code: any) => {
        this.form.verification = code;
      },
    });
    
  }

  public async login() {
    const reg = /^1[3456789]\d{9}$/;
    if (!reg.test(this.form.mobile)) {
      Toastrs.error(`请输入正确的手机号码`);
      return;
    }

    if (this.form.verification.length != 4) {
      Toastrs.error(`请输入正确的验证码`);
      return;
    }
    let obj = this.getUrlParams((this as any).login_url);
    delete obj.appid;
    delete obj.redirect_uri;
    delete obj.user_type;
    delete obj.channels;
    const res: any = await loginUser({
      source_appid: (this as any).appid,
      channels: "sms",
      ...obj,
      ...this.form,
    });
    if (res.code == 200 || res.code ==0) {
      (this as any).loginSuccess(res.data.token, {
        openid: res.data.third_info.open_id,
      });
    }
  }

  getUrlParams(urlStr: string) {
    var url = "?" + urlStr.split("?")[1];
    var theRequest: any = new Object();
    if (url.indexOf("?") != -1) {
      var str = url.substr(1);
      let strs = str.split("&");
      for (var i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
      }
    }
    return theRequest;
  }
}
