import Env from './env'
export default function weixinLogin() {
    if (GetQueryString('code')) {
        // let url = localStorage.getItem('redirect_uri')
        // localStorage.removeItem('redirect_uri')
        return GetQueryString('code')
    } else {
        wxlogin()
    }
}

function wxlogin() {
    let appid = GetQueryString('wxappid') || 'wx30558f4b9433f66e'
    let scope = GetQueryString('scope') || 'snsapi_userinfo'
    let state = GetQueryString('scope') || '1'

    let wxUrl = `//m.hyjt8888.com/${Env() == 'pro' ? 'wxlogin': 'testwxlogin'}?appid=${appid}&redirect_uri=${encodeURIComponent(window.location.origin + window.location.pathname)}&response_type=code&scope=${scope}&state=${state}`
    window.location.replace(wxUrl)
}

function GetQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
}
